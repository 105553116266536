import { render } from "./LoadingSpinner.vue?vue&type=template&id=7871f09c"
import script from "./LoadingSpinner.vue?vue&type=script&lang=js"
export * from "./LoadingSpinner.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7871f09c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7871f09c', script)) {
    api.reload('7871f09c', script)
  }
  
  module.hot.accept("./LoadingSpinner.vue?vue&type=template&id=7871f09c", () => {
    api.rerender('7871f09c', render)
  })

}

script.__file = "src/components/spinners/LoadingSpinner.vue"

export default script