import { render } from "./SpaceFormPageFour.vue?vue&type=template&id=e4b79006"
import script from "./SpaceFormPageFour.vue?vue&type=script&lang=js"
export * from "./SpaceFormPageFour.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "e4b79006"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('e4b79006', script)) {
    api.reload('e4b79006', script)
  }
  
  module.hot.accept("./SpaceFormPageFour.vue?vue&type=template&id=e4b79006", () => {
    api.rerender('e4b79006', render)
  })

}

script.__file = "src/components/dashboard/space-form-pages/SpaceFormPageFour.vue"

export default script